<template>
	<div class="news-wrap">
		<el-form label-width="100px" :model="form" ref="form" :rules="rules">
			<el-form-item label="标题" prop="title">
				<el-input v-model="form.title" placeholder="请输入企业要闻标题" />
			</el-form-item>
			<el-form-item label="SEO关键词">
				<el-input placeholder="请输入SEO关键词,用 ' , ' 间隔" v-model="form.keywords" />
			</el-form-item>
			<el-form-item label="SEO描述">
				<el-input placeholder="请输入SEO描述" v-model="form.description" />
			</el-form-item>
			<!-- <template v-if="form.news_catid==19||form.news_catid==20||form.news_catid==21">
				<el-form-item label="展会时间"> -->
					<!-- <el-date-picker v-model="form.start_time" type="datetime" placeholder="选择展会开始时间" value-format="yyyy-MM-dd hh:mm:ss" /> -->
					<!-- <el-date-picker v-model="dateData" type="datetimerange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd hh:mm:ss" @change="handleDate" />
				</el-form-item> -->
				<!-- <el-form-item label="展会结束时间">
					<el-date-picker v-model="form.end_time" type="datetime" placeholder="选择展会结束时间" value-format="yyyy-MM-dd hh:mm:ss" />
				</el-form-item> -->
				<!-- <el-form-item label="展会开办地址">
					<el-input placeholder="请输入SEO描述" v-model="form.address" />
				</el-form-item>
			</template> -->
			<el-form-item label="相关产品链接" v-if="form.news_catid!=17&&form.news_catid!=15">
				<el-input placeholder="请输入相关产品链接" v-model="form.goods_url" />
			</el-form-item>
			<el-form-item :label="form.news_catid!=17?'文章来源':'发放单位'" v-if="form.news_catid!=15">
				<el-input :placeholder="form.news_catid!=17?'请输入文章来源':'请输入发放单位'" v-model="form.news_source" />
			</el-form-item>
			<el-form-item label="封面图" v-if="form.news_catid!=15" prop="imageUrl">
				<el-upload class="avatar-uploader" :action="picUrl" 
					:show-file-list="false"
					element-loading-text="拼命加载中……"
					:on-progress="change"
					:before-upload="beforeAvatarUpload"
					v-loading.fullscreen.lock="loading"
					element-loading-background="rgba(250, 250, 250, 0.3)"
					:show-file-list="false" :data="fileInfo"
					:on-success="handleAvatarSuccess" accept="image/jpeg,image/png">
					<img v-if="imageUrl" :src="imageUrl" :onerror="imgSrc" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div class="tishinews">
					小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
					<br/>图片建议尺寸: 320*320像素
				</div>
			</el-form-item>
			<el-form-item label="技术文档" v-if="form.news_catid==16">
				<el-upload action="/uploadFile" :data="{user_id}" :on-remove="handleBookRemove"
					:before-remove="beforeBookRemove" :limit="1" :on-exceed="handleBookExceed"
					:on-success="handleBookSuceess" accept=".doc,.pdf,.docx,.wls,.wlsx">
					<el-button size="small" type="primary">点击上传</el-button>
					<div slot="tip" class="el-upload__tip">只能上传doc/pdf/docx/wls/wlsx文件</div>
				</el-upload>
			</el-form-item>
			<el-form-item label="详细内容" prop="news_content">
				<tinyMce :content="form.news_content" :change.sync="form.news_content" />
			</el-form-item>
			<div class="submit-wrap">
				<el-button type="primary" @click="handleSubmit">提交</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	import tinyMce from '@/components/plug/tinyMce'
	export default {
		data() {
			let validatePhoto = (rule, value, callback) => {
				if(this.imageUrl == "" || this.imageUrl == undefined || this.imageUrl == null){
					return callback(new Error('请上传封面图'));
				}else{
					callback()
				}
			}
			return {
				loading:false,
				imgSrc:'this.src="' + require('../../assets/img/mr.jpg') + '"',
				fileInfo:{
					type:"Member",
				},
				user_id: this.$store.state.USER_ID,
				imageUrl: '',
				catList: [],
				newsCatList: [],
				form: {
					id: '',
					title: '',
					news_catid: 12,
					keywords: '',
					description: '',
					goods_url: '',
					news_source: '',
					thumb: '',
					news_content: ''
				},
				dateData: [],
				rules: {
					title: [{
						required: true,
						message: '请输入标题',
					}],
					news_catid: [{
						required: true,
						message: '请选择所在栏目',
					}],
					imageUrl:[{
						validator: validatePhoto,
						required: true
					}],
					news_content: [{
						required: true,
						validator: (rule, value, callback) => {
							if (this.form.news_content === '') {
								callback(new Error('请输入详细内容'));
							} else {
								callback();
							}
						}
					}]
				},
				picUrl:''
			}
		},
		created() {
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
		},
		methods: {
			change(event, file, fileList){
				this.loading = true;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			handleAvatarSuccess(res, file) {
				if(res.code == 200){
					this.loading = false;
					this.form.thumb = res.data.src
					this.imageUrl = URL.createObjectURL(file.raw);
				}else{
					this.loading = false;
					this.$message.error(res.msg)
				}
			},
			handleSubmit() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						if (this.form.news_content.includes('"static/tinymce/plugins/bdmap/bd')) {
							this.form.news_content = this.form.news_content.replace(
								/"static\/tinymce\/plugins\/bdmap\/bd/g,
								'"/static/tinymce/plugins/bdmap/bd')
						}
						this.$emit('eventForm', this.form)
					} else {
						this.$message.error('请补全必填项')
						return false
					}
				});
			},
			handleBookRemove(file, fileList) {
				//console.log(file, fileList);
			},
			handleBookExceed(files, fileList) {
				this.$message.warning('技术文档只限上传一份，可先删除现有文档后在提交');
			},
			beforeBookRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			handleBookSuceess(res, file, fileList) {
				if (res.code == 200) {
					this.form.book = res.data
				}else{
					this.$message.error(res.msg)
				}
			},
			handleDate(val) {
				this.form.start_time = val[0]
				this.form.end_time = val[1]
			}
		},
		components:{
			tinyMce
		},
		props: {
			newsForm: {
				type: Object,
				default () {
					return {}
				}
			}
		},
		watch: {
			newsForm(val) {
				this.form.id = val.id
				this.form.title = val.news_title
				this.form.catid = val.catid
				this.form.news_catid = val.news_catid
				this.form.keywords = val.keywords
				this.form.description = val.description
				this.form.start_time = val.start_time
				this.form.end_time = val.end_time
				this.dateData.push(val.start_time)
				this.dateData.push(val.end_time)
				this.form.address = val.address
				this.form.goods_url = val.goods_url
				this.form.news_source = val.news_source
				this.form.book = val.book
				this.form.thumb = val.thumb
				this.imageUrl = val.thumb ? this.$store.state.IMG_URL + '/' + val.thumb :''
				this.form.news_content = val.news_content
				document.body.scrollTop = document.documentElement.scrollTop = 0
			}
		}
	}
</script>

<style scoped>
	.el-form {
		padding: 20px;
		max-width: 1020px;
	}

	.news-wrap>>>.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.news-wrap>>>.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.news-wrap>>>.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.news-wrap>>>.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}

	.submit-wrap {
		text-align: center;
	}
	.tishinews{
		color: #666ee8;
	}
</style>
